/**
 *  routes.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO
 *  @description: Manejo de todas las rutas de la aplicación
 * 	@process: 4
 */

import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider, addLocaleData } from "react-intl";
import { Consumer } from "../context";

// Web Components
import Header from "../components/header/header";

// Web Pages
import Login from "../pages/login";
import Reservaciones from "../pages/reservaciones";

//locales
import es from 'react-intl/locale-data/es';
addLocaleData([...es]);

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={props =>
          this.props.context.auth ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            /> 
          )
        }
      />
    );
    return (
      <IntlProvider locale="es">
        <BrowserRouter basename="/">
          <div className="admin flex">
            <div className="column">
              {/*<Header />*/}
              <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/login" exact component={Login} />
                <PrivateRoute path="/reservaciones" exact component={Reservaciones} />
              </Switch>
            </div>
          </div>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

export default Consumer(Routes);
