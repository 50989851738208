import React, { Component } from "react";
import Request from '../core/httpClient';
import { Consumer } from '../context';
import socket from './../core/socket';
import Logo from '../img/Imagotipo.png';

//WebComponents
import { cifrar } from '../components/encrypt/encrypt';

// Images
import Images from "../img/index";

const req = new Request();

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const auth = this.props.context.auth;
    if(auth) {
      window.location = '/reservaciones';
    }
  }

  async login(event) {
    event.preventDefault();
    this.setState({loading: true});

    const username = event.target.username.value;
    const password = cifrar(event.target.password.value);
    const data = {
      username,
      password
    };
    const res = await req.post('/admin/users/loginAgencia', data);
    this.setState({message: res.message});
    if(res.user) {
      this.props.context.login(res.user);
      window.location = '/reservaciones';
    }
    this.setState({loading: false});
  }

  render() {
    return (
      <div className="login justify-center align-center">
        <div className="container column">
          <div className="justify-center">
            <div className="login-modal column">
              <div className="white-space-32" />
              <div className="logo justify-center">
                <img
                  src={Logo}
                  alt="Logo Vamos Cancun"
                  title="Vamos Cancun"
                />
              </div>
              <div className="white-space-16" />
              {/*<div className="useri justify-center">
                <i class="fas fa-user"></i>
              </div>*/}
              <h2 className="color-black text-center">Iniciar sesión</h2>
              <div className="white-space-24" />
              <form className="column align-center" onSubmit={this.login.bind(this)} >
                <input
                  type="text"
                  name="username"
                  className="input input-text"
                  placeholder="USUARIO"
                  maxLength="100"
                  required
                />
                <div className="white-space-8" />
                <input type="password" name="password" className="input input-text" placeholder="CONTRASEÑA" /*minLength="8"*/ maxLength="100" required />
                <div className="white-space-8" />
                <button className="btn btn-green" type="submit">
                  {this.state.loading ? (
                    <i className="fas fa-spinner fa-spin" />
                  ) : (
                      <i className="fas fa-sign-in-alt" />
                    )}
                  &nbsp; INGRESAR
                </button>
                <div className="white-space-8"></div>
                <div className="column align-center">
                    {this.state.message}
                </div>
                <div className="white-space-32" />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Consumer(Login);
