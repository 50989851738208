import React from 'react';
import { Link } from 'react-router-dom';

const Aside = function() {
    return(
        <div className="aside column">
            <div className="menu-element">
                <div className="icon auto align-center">
                    <i className="fas fa-box-open"></i>
                </div>
                <div className="menu-element-name align-center">
                    <Link to="/reservaciones">
                        RESERVACIONES
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Aside;