import React, { Component } from "react";
import moment from "moment-timezone";
import Request from '../core/httpClient';

// Web Components
import Aside from "../components/aside/aside";
import Dates from "../components/oders/dates";
import Filters from "../components/oders/filters";
import OrdersTable from "../components/oders/orders-table";
import Paginator from "../components/paginator/paginator";
import OrderDetail from "../components/modals/order-details";
import ReservationDetail from "../components/modals/reservation-detail";
import Header from "../components/header/header";

const req = new Request();

class Pedidos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      filters: {},
      order: 0,
      reservations: [],
      reservation: [],
      estado: '',
      folio: ''
    };
  }

  componentDidMount() {
    this.findOrders(moment().add(-7, 'day'), moment());
  }

  modalReservationDetail(reservation) {
    this.setState({ reservation: reservation });
    const modal = document.getElementById("modal-reservation-detail");
    modal.style.display = 'flex';
    modal.classList.remove('hidden');
  }

  async findOrders(start, end) {
    this.setState({loading: true, message: ''});
    
    let user = window.localStorage.getItem('vca_ctx');
    let id_agencia = 0;
    if (user) {
      user = JSON.parse(user);
      console.log("User: ", user);
      id_agencia = user.user.id_agencia
    }

    const data = {
      start: moment(start).format("YYYY-MM-DD 00:00:00"),
      end: moment(end).format("YYYY-MM-DD 23:59:59"),
      id_agencia
    };
    const res = await req.post('/admin/reservations/getReservationsAgentes', data);
    this.setState({message: res.message});
    if(res.reservations) {
      console.log(res);
      this.setState({reservations: res.reservations});
    } else {
		this.setState({reservations: []});
	}
    this.setState({loading: false});
  }

  async filterOrders(data) {
    console.log(data);
    /*const data = {
      start: moment(start).format("YYYY-MM-DD 00:00:00"),
      end: moment(end).format("YYYY-MM-DD 23:59.59")
    };
    const res = await req.post('/admin/reservations/get', data);*/
    this.setState({ folio: data.folio, estado: data.estado });
  }

  setpage(page) {
    this.setState({ page: page });
  }

  render() {
    let orders = this.state.reservations ? this.state.reservations : [];
    if (this.state.folio) {
      orders = orders.filter(
        order => order.folio.toLowerCase().indexOf(this.state.folio.toLowerCase()) !== -1
      );
      console.log("Folio order: ", orders);
    }
    if (this.state.estado) {
      orders = orders.filter(
        order => order.estado.indexOf(this.state.estado) !== -1
      );
      console.log("Estado order: ", orders);
    }

    return (
      <div className="workspace">
        <div className="column">
          <Header/>
          <div>
            <Aside />
            <div className="control-content reservations justify-center">
              <div className="container column">
                <div className="white-space-32" />
                <h3 className="color-black">RESERVACIONES</h3>
                <div className="white-space-8" />
                <Dates
                  setDates={this.findOrders.bind(this)}
                  loading={this.props.loading}
                />
                <div className="white-space-24" />
                <Filters filter={this.filterOrders.bind(this)} />
                <div className="white-space-24" />
                {orders.length !== 0 ?
                <OrdersTable
                  page={this.state.page}
                  orders={orders}
                />:
                <div className="justify-center">
                  <div className="justify-center align-center">
                    <h3 className="color-black">No hay reservaciones para mostrar.</h3>
                  </div>
                </div>}
                <div className="white-space-32" />
                {orders.length > 10 ?
                  <Paginator
                    setpage={this.setpage.bind(this)}
                    items={Math.ceil(orders.length / 10)}
                  /> : null}
                <div className="white-space-32"></div>
              </div>
            </div>
            <OrderDetail
              order={this.state.order}
              dispatch={this.props.dispatch}
              loading={this.props.update_order_loading}
            />
          </div>
        </div>
      </div>      
    );
  }
}

export default Pedidos;