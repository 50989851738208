import React, { Component } from "react";
import moment from "moment-timezone";
import FormattedNumber from "react-number-format";
import Request from '../../core/httpClient';
const req = new Request();

class ReservationDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cliente: '', 
            titulo: '',
            locale: '',
            proveedores: [],
            provider: '',
            costo_menor: '', 
            costo_mayor: '',
            telefono: '',
            id_proveedor: '',
            finalizado: '',
            proveedor: '',
            confirmado: ''
        };
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(this.props);
        if (prevProps.reservation !== this.props.reservation) {
            this.loadProviders();   
            this.setState({
                estado: this.props.reservation.estado,
                provider: this.props.reservation.id_proveedor,
                finalizado: this.props.reservation.confirmacion,
                proveedor: this.props.reservation.proveedor,
                telefono: this.props.reservation.telefono,
				confirmado: this.props.reservation.confirmacion === 1 ? true : false,
				id_opcion: this.props.reservation.id_opcion,
				opcion: this.props.reservation.opcion
            });
        }
    }

    async loadProviders() {
        const res = await req.post('/admin/providers/get');
        if (res.providers) {
            const providers = [];
            res.providers.forEach((item, index) => {
                let tours = '';
                if (item.tours !== null && item.tours !== '') {
                    if (item.tours.indexOf("[") !== -1) {
                        tours = JSON.parse(item.tours);
                        if(tours.length > 0) {
                            tours.forEach(tour => {
                                if (tour.id_producto.toString() === this.props.reservation.id_producto.toString()) {
                                    providers.push({...tour, id_proveedor: item.id_proveedor, proveedor: item.proveedor, telefono: item.telefono });
                                } 

                                if (this.props.reservation.id_proveedor !== null) {
                                    if (item.id_proveedor === this.props.reservation.id_proveedor && tour.id_producto.toString() === this.props.reservation.id_producto.toString()) {
                                        this.setState({ costo_menor: tour.costoMenor, costo_mayor: tour.costoAdulto });
                                    } 
                                } else {
                                    this.setState({ costo_mayor: 0, costo_menor: 0 });
                                }
                            });
                        }
                    }
                }
            });

            this.setState({proveedores: providers});
        }
    }

    close() {
        const modal = document.getElementById("modal-reservation-detail");
        modal.style.display = "none";

        if (this.props.reservation.confirmacion !== 1) {
            if (this.props.reservation.id_proveedor !== null) {
                let tour = this.state.proveedores.filter(item => item.id_proveedor.toString() === this.props.reservation.id_proveedor.toString());
                console.log("Tour:", tour);
                if (Array.isArray(tour)) {
					if(tour[0]) {
						this.setState({ costo_mayor: tour[0].costoAdulto, costo_menor: tour[0].costoMenor });
					}
                }
            } else {
                this.setState({ costo_mayor: 0, costo_menor: 0 });
            }
            
            this.setState({ 
                finalizado: 0, 
                confirmado: false, 
                provider: this.props.reservation.id_proveedor
            });
        }
    }

    async changeStatus(event) {
        event.preventDefault();
        const estado = event.target.estado.value;

        if (estado !== 'FINALIZADO') {
            let data = '';
            if (this.state.proveedores.length > 0 && this.state.provider !== null && this.state.id_proveedor !== 0 && this.state.estado !== 'CANCELADO') {
                if (this.state.finalizado === 1) {
                    data = {
                        id_proveedor: this.state.id_proveedor,
                        costo_menor: this.state.costo_menor,
                        costo_mayor: this.state.costo_mayor,
                        estado: event.target.estado.value,
                        id: this.props.reservation.id_reservacion,
                        confirmacion: this.state.finalizado,
                        type: 3
                    };
                } else {
                    data = {
                        id_proveedor: this.state.id_proveedor,
                        estado: event.target.estado.value,
                        id: this.props.reservation.id_reservacion,
                        type: 2
                    };
                }
            } else {
                data = {
                    estado: event.target.estado.value,
                    id: this.props.reservation.id_reservacion,
                    type: 1
                };
            }

            const res = await req.post('/admin/reservations/update', data);
            this.setState({ message: res.message });
            if (res.updated) {
                this.close();
                this.props.findOrders(moment().add(-7, 'day'), moment());
            }   
        } else {
            if (this.state.finalizado === 1) {
                const data = {
                    id_proveedor: this.state.id_proveedor,
                    costo_menor: this.state.costo_menor,
                    costo_mayor: this.state.costo_mayor,
                    estado: event.target.estado.value,
                    id: this.props.reservation.id_reservacion,
                    confirmacion: this.state.finalizado,
                    data_provider: true
                };

                const res = await req.post('/admin/reservations/update', data);
                this.setState({ message: res.message });
                if (res.updated) {
                    this.close();
                    this.props.findOrders(moment().add(-7, 'day'), moment());
                }   
            } else {
                alert("No es posible finalizar reservación sin datos de proveedor confirmados.");
            }
        }
    }

    async sendWhatsApp() {
        const data = {
            id_proveedor: this.state.id_proveedor,
            costo_menor: this.state.costo_menor,
            costo_mayor: this.state.costo_mayor,
            id_reservacion: this.props.reservation.id_reservacion
        };
        const res = await req.post('/admin/providers/edit/reservation', data);
        if (res.updated) {
            console.log(res.updated);
        }
    }

    onChangeProvider(e) {
        const proveedor = document.getElementById('provider').value;
        if (proveedor !== "") {
            let tour = this.state.proveedores.filter(item => item.id_proveedor.toString() === proveedor.toString());
            this.setState({ provider: e.target.value, id_proveedor: tour[0].id_proveedor, proveedor: tour[0].proveedor, costo_menor: tour[0].costoMenor, costo_mayor: tour[0].costoAdulto, telefono: tour[0].telefono });   
        } else {
            console.log(e.target.value);
            this.setState({ provider: e.target.value, id_proveedor: 0, costo_menor: 0, costo_mayor: 0 });
        }
    }

    providerConfirmation() {
        console.log("Costo menor: ", this.state.costo_menor);
        let status = document.getElementById('confirmation').value;
        status = status ? 1 : 0;
        this.setState({ finalizado: status, confirmado: true });

        const modal = document.getElementById('modal_provider_confirmation');
        if (modal) {
            modal.classList.add("hidden");
        }
    }

    render() {
        let locale = window.localStorage.getItem('cto_locale');
        if (!locale) {
        locale = 'es';
        }   

        let cliente = '', titulo = '', opcion;
        if (this.props.reservation.cliente) {
            cliente = JSON.parse(this.props.reservation.cliente);
        }

        if (this.props.reservation.titulo) {
            titulo = JSON.parse(this.props.reservation.titulo);
            titulo = titulo[locale];
        }

        if (this.props.reservation.opcion) {
            opcion = JSON.parse(this.props.reservation.opcion);
            opcion = opcion[locale];
        }

        return(
            <div className="modal column justify-center align-center faster hidden" id="modal-reservation-detail">
                <form className="container modal-content align-center column">
                    <div className="column modal-head">
                        {this.props.reservation.estado !== "CANCELADO" && this.props.reservation.estado !== "FINALIZADO" ?
                            <div className="white-space-16"></div> : <div className="close-modal-reservation justify-end">
                                <a onClick={this.close.bind(this)}>x</a>
                            </div>}
                        <h3 className="text-center">
                            DETALLE DE RESERVACIÓN
                        </h3>
                        <div className="white-space-16"></div>
                    </div>
                    <div className="white-space-16"></div>
                    <div className="reservation-detail-data justify-center">
                        <div className="container">
                            <div className="reservation-detail-inputs column">
                                <div className="first row">
                                    <div className="up column">
                                        <h4>Datos del cliente</h4>
                                        <div className="white-space-8"></div>
                                        <div className="up-data">
                                            <div className="left">
                                                <label>Nombre(s): </label>
                                            </div>
                                            <div className="right">
                                                <label>{cliente.name}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="up-data">
                                            <div className="left">
                                                <label>Apellido(s): </label>
                                            </div>
                                            <div className="right">
                                                <label>{cliente.lastName}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="up-data">
                                            <div className="left">
                                                <label>Email: </label>
                                            </div>
                                            <div className="right">
                                                <label>{cliente.email}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="up-data">
                                            <div className="left">
                                                <label>País: </label>
                                            </div>
                                            <div className="right">
                                                <label>{cliente.country}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="up-data">
                                            <div className="left">
                                                <label>Teléfono: </label>
                                            </div>
                                            <div className="right">
                                                <label>{cliente.telephone}</label>
                                            </div>
                                        </div>
                                        {cliente.comment !== '' ?
                                        <React.Fragment>
                                            <div className="white-space-8"></div>
                                            <div className="up-data">
                                                <div className="left">
                                                    <label>Comentario: </label>
                                                </div>
                                                <div className="right">
                                                    <label>{cliente.comment}</label>
                                                </div>
                                            </div>
                                            <div className="white-space-16"></div>
                                        </React.Fragment> : <div className="white-space-16"></div>}
                                    </div>
                                    <div className="down column">
                                        <h4>Datos de reservación</h4>
                                        <div className="white-space-8"></div>
                                        <div className="down-data">
                                            <div className="left">
                                                <label>Adultos: </label>
                                            </div>
                                            <div className="right">
                                                <label>{this.props.reservation.adultos}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="down-data">
                                            <div className="left">
                                                <label>Menores: </label>
                                            </div>
                                            <div className="right">
                                                <label>{this.props.reservation.menores}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="down-data">
                                            <div className="left">
                                                <label>Hotel: </label>
                                            </div>
                                            <div className="right">
                                                <label>{isNaN(this.props.reservation.hotel) ? this.props.reservation.hotel : this.props.reservation.nombre}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="down-data">
                                            <div className="left">
                                                <label>Folio: </label>
                                            </div>
                                            <div className="right">
                                                <label>{this.props.reservation.folio}</label>
                                            </div>
                                        </div>
                                        {this.props.reservation.id_opcion ?
                                        <React.Fragment>
                                            <div className="white-space-8"></div>
                                            <div className="down-data">
                                                <div className="left">
                                                    <label>Variación: </label>
                                                </div>
                                                <div className="right">
                                                    <label>{opcion}</label>
                                                </div>
                                            </div>
                                        </React.Fragment> : null}
                                    </div>
                                </div>
                                <div className="middle row">
                                    <div className="left column">
                                        <div className="white-space-8"></div>
                                        <h4>Datos de tour</h4>
                                        <div className="white-space-8"></div>
                                        <div className="middle-data">
                                            <div className="left">
                                                <label>Tour: </label>
                                            </div>
                                            <div className="right">
                                                <label>{titulo}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="middle-data">
                                            <div className="left">
                                                <label>Fecha: </label>
                                            </div>
                                            <div className="right">
                                                <label>{moment(this.props.reservation.fecha_tour).tz('America/cancun').format("DD/MM/YYYY")}</label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="middle-data">
                                            <div className="left">
                                                <label>Precio adulto: </label>
                                            </div>
                                            <div className="right">
                                                <label><FormattedNumber
                                                    prefix={'$'}
                                                    displayType={'text'}
                                                    value={this.props.reservation.precio_adulto}
                                                /></label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="middle-data">
                                            <div className="left">
                                                <label>Precio niño: </label>
                                            </div>
                                            <div className="right">
                                                <label><FormattedNumber
                                                    prefix={'$'}
                                                    displayType={'text'}
                                                    value={this.props.reservation.precio_menor}
                                                /></label>
                                            </div>
                                        </div>
                                        {/*<div className="white-space-8"></div>
                                        <div className="middle-data">
                                            <div className="left">
                                                <label>Descuento: </label>
                                            </div>
                                            <div className="right">
                                                <label>{this.props.reservation.descuento} %</label>
                                            </div>
                                        </div>*/}
                                        <div className="white-space-16"></div>
                                    </div>
                                    <div className="right column">
                                        <div className="white-space-8"></div>
                                        <h4>Datos de proveedor</h4>
                                        <div className="white-space-8"></div>
                                        {this.state.finalizado !== 1 && this.state.estado !== 'CANCELADO' ?
                                        <select className="input" name="provider" id="provider" value={this.state.provider ? this.state.provider : ''} onChange={this.onChangeProvider.bind(this)} required>
                                            <option value="">Seleccionar proveedor...</option>
                                            {this.state.proveedores.map((item, index) => 
                                                <option value={item.id_proveedor} key={index}>{item.proveedor}</option>    
                                            )}
                                        </select> : 
                                        <div className="middle-data">
                                            <div className="left">
                                                <label>Proveedor: </label>
                                            </div>
                                            <div className="right">
                                                <label>{this.state.proveedor}</label>
                                            </div>
                                        </div> }
                                        <div className="white-space-8"></div>
                                        <div className="middle-data">
                                            <div className="left">
                                                <label>Costo adulto: </label>
                                            </div>
                                            <div className="right">
                                                <label><FormattedNumber
                                                    prefix={'$'}
                                                    displayType={'text'}
                                                    value={this.props.reservation.costo_mayor ? this.props.reservation.costo_mayor : this.state.costo_mayor ? this.state.costo_mayor : 0}
                                                /></label>
                                            </div>
                                        </div>
                                        <div className="white-space-8"></div>
                                        <div className="middle-data">
                                            <div className="left">
                                                <label>Costo menor: </label>
                                            </div>
                                            <div className="right">
                                                <label><FormattedNumber
                                                    prefix={'$'}
                                                    displayType={'text'}
                                                    value={this.props.reservation.costo_menor ? this.props.reservation.costo_menor : this.state.costo_menor ? this.state.costo_menor : 0}
                                                /></label>
                                            </div>
                                        </div>
                                        {this.state.proveedores.length > 0 && !this.state.confirmado && this.state.provider !== null && this.state.provider !== "" && this.state.estado !== 'CANCELADO' ?
                                            <div className="whats-provider justify-center">
                                                <a className="btn color-white" title="Reservar con proveedor" /*onClick={this.sendWhatsApp.bind(this)}*/
                                                    href={`https://api.whatsapp.com/send?phone=521${this.state.telefono}&text=Solicitamos%20una%20reservación%20para%20el%20tour:%20${titulo}%20para%20el%20día%20${moment(this.props.reservation.fecha_tour).format("DD/MM/YYYY")}%20para%20${this.props.reservation.adultos}%20Adultos%20y%20${this.props.reservation.menores}%20Menores.`}
                                                    target="_blank" rel="noopener">
                                                    <i className="fab fa-whatsapp"></i>
                                                </a>
                                            </div> :
                                        <div className="white-space-8"></div>}
                                        {this.state.confirmado ?
                                        <React.Fragment>
                                            <div className="provider-status align-center ">
                                                <label>Confirmado</label>
                                            </div>
                                            <div className="white-space-8"></div>
                                        </React.Fragment> : this.state.proveedores.length > 0 && this.state.provider !== null && this.state.provider !== "" && this.state.estado !== 'CANCELADO' ?
                                        <React.Fragment>
                                            <div className="provider-status align-center ">
                                                <label>Confirmado</label>
                                                <input type="checkbox" id="confirmation" checked={this.state.finalizado === 1 ? true : false}
                                                    onChange={() => {
                                                        var modal = document.getElementById('modal_provider_confirmation');
                                                        if (modal) {
                                                            modal.classList.remove("hidden");
                                                        }
                                                    }}/>
                                            </div>
                                            <div className="white-space-8"></div>
                                        </React.Fragment>
                                        : null}
                                    </div>
                                </div>
                                <div className="white-space-16"></div>
                                {this.props.reservation.estado !== "CANCELADO" && this.props.reservation.estado !== "FINALIZADO" ?
                                <form className="change-status justify-end" onSubmit={this.changeStatus.bind(this)}>
                                    <div className="justify-center">
                                        <select name="change-status-select" name="estado" value={this.state.estado} className="input input-status" onChange={(event) => this.setState({ estado: event.target.value })} required>
                                            <option value="">Cambiar estado...</option>
                                            <option value="RESERVADO">RESERVADO</option>
                                            <option value="PAGADO">PAGADO</option>
                                            <option value="CANCELADO">CANCELADO</option>
                                            <option value="FINALIZADO">FINALIZADO</option>
                                        </select>
                                    </div>
                                    <div className="justify-center">
                                        <div className="save justify-end">
                                            <button className="btn-save btn-green" type="submit">Guardar</button>
                                        </div>
                                        <div className="cancel justify-end">
                                            <button className="btn-save btn-darkRed" type="button" onClick={this.close.bind(this)}>Cancelar</button>
                                        </div>
                                    </div>
                                </form> : null}
                                <div className="white-space-24"></div>
                            </div>
                        </div>
                    </div>
                </form>
                {/* Modal confirmación */}
                <div className="flex modal hidden" id="modal_provider_confirmation">
                    <div className="container modal-content-confirmation column">
                        <div className="modal-header justify-center">
                            <h2 className="color-white">
                                CONFIRMACIÓN
                            </h2>
                        </div>
                        <div className="modal-body column">
                            <div className="white-space-32"></div>
                            <div className="text-confirmation column justify-center">
                                <h4 className="text-center color-black">Una vez confirmado un proveedor este no puede ser modificado.</h4>
                                <h4 className="text-center color-black">¿Desea continuar?</h4>
                            </div>
                            <div className="white-space-32"></div>
                            <div className="row justify-center">
                                {this.state.delete_loading ?
                                    <div className="btn btn-aqua">
                                        <i className="fas fa-spinner fa-spin"></i>
                                    </div>
                                    :
                                    <button className="btn btn-aqua" onClick={this.providerConfirmation.bind(this)}>
                                        ACEPTAR
                                    </button>
                                }
                                &nbsp;
                                <button className="btn btn-red" onClick={() => {
                                    const modal = document.getElementById('modal_provider_confirmation');
                                    if (modal) {
                                        modal.classList.add("hidden");
                                    }
                                }}>
                                    CANCELAR
                                </button>
                            </div>
                            <div className="white-space-16"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReservationDetail;